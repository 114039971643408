import React from "react";
import '../about-details/About-Styles.css'


function AboutDetails() {
    return (
        <div className="about-container">
            <h1>Our History</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore, eos deserunt unde numquam aliquid accusantium aperiam nam tempora libero qui rerum doloribus distinctio praesentium at consequatur quos voluptatem similique iste!</p>

            <h1>Our Vision</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore, eos deserunt unde numquam aliquid accusantium aperiam nam tempora libero qui rerum doloribus distinctio praesentium at consequatur quos voluptatem similique iste!</p>
            
            <h1>Our Mission</h1>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore, eos deserunt unde numquam aliquid accusantium aperiam nam tempora libero qui rerum doloribus distinctio praesentium at consequatur quos voluptatem similique iste!</p>
            
        </div>
    );

}


export default AboutDetails;